// import logo from './logo.svg';
import "./App.css";
import Home from "./components/Home/Home";
import Particle from "./components/Particle";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import About from "./components/About/About";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Projects from "./components/Projects/Projects";

function App() {
  return (
    <Router>
      <Navbar/>
    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/about" element={<About/>}></Route>
      <Route path="/projects" element={<Projects/>}></Route>
      <Route path="/resume" element={<About/>}></Route>
    </Routes>
    <Footer/>
  </Router>
  
  );
}

export default App;

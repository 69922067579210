import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom"; 
import { CgGitFork } from "react-icons/cg";
import { ImBlog } from "react-icons/im";
import { CgFileDocument } from "react-icons/cg";
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";

function Navbar() {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [bgColor, setBgColor] = useState(false);


  function changeBg() {
    window.scrollY >= 30 ? setBgColor(true) : setBgColor(false);
  }

  window.addEventListener("scroll", changeBg);


  function toggleNav() {
    menuExpanded ? setMenuExpanded(false) : setMenuExpanded(true);
  }
  return (
    <header
      className={`transition-all duration-500 z-10  backdrop-blur-sm    md:bg-transparent fixed top-0 left-0 w-full h-14 md:!h-14 overflow-hidden ${
        menuExpanded ? "!h-full" : ""
      } ${bgColor ? "bg-[#12193dc4] " : ""}`}
    >
      <nav className="p-nav flex justify-between m-4 md:mx-32 text-xl gap-5 text-white  ">
        <div className="logo text-3xl">
          TT <span className="text-red-600  ">.</span>
        </div>

        <div className="nav-menu  ">
          <ul
            className=" 
          nav-list gap-20 left-40 top-20 md:top-0 flex  flex-col pb-10 md:flex-row absolute md:static "
            id="nav-list" onClick={toggleNav}
          >
            <NavLink
              className="navbar-item flex relative gap-1"
              activeClassName="is-active"
              to="/"
              exact
            >
              <AiOutlineHome style={{ marginTop: "-3px" }} size={30} />
              Home
            </NavLink>

            <NavLink
              className="navbar-item flex gap-1"
              activeClassName="is-active"
              to="/about"
              exact
            >
              <AiOutlineUser style={{ marginTop: "-3px" }} size={30} />
              About
            </NavLink>

            <NavLink
              className="navbar-item flex gap-1"
              activeClassName="is-active"
              to="/projects"
              exact
            >
              <AiOutlineFundProjectionScreen
                style={{ marginTop: "-3px" }}
                size={30}
              />
              Projects
            </NavLink>
            <NavLink
              className="navbar-item flex gap-1"
              activeClassName="is-active"
              to="/"
              exact
            >
              <CgFileDocument size={30} />
              Resume
            </NavLink>
            <Link

              className="navbar-item flex gap-1"
              activeClassName="is-active"
              to={{
                pathname:
                  "",
              }}
              target="_blank"
            >
              <a href="https://tutorend.com" className="flex gap-2">
              <ImBlog style={{ marginTop: "-3px" }} size={30} />
              Blogs
              </a>
              
            </Link>
          </ul>

          <div className="md:hidden">
            <div
              class={`toggle-btn hidden ${menuExpanded ? "open" : ""}  `}
              onClick={toggleNav}
            >
              <div className="btn-icon "></div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;

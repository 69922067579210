import React from "react";
import Container from "../Layout/Container";
import { motion } from "framer-motion";
import AboutImg from './../../assets/images/about.png'

function About() {
  return (
    <Container>
   
      <section className="about-me flex flex-col md:flex-row gap-8 justify-between w-full text-center ">
          <div className="lmi-right  h-full w-full text-xl ">
            <h1 className=" text-5xl font-Raleway ">Who Am I</h1>
            <br />
            <p>
              I got my first Computer/ Laptop when I was in Grade 9, I didn't
              have any idea how to use it and I didn't get a chance to attend a
              computer class because there wasn't any near me. So I started
              googling, but data was too expensive and there was no presence of
              Broadband in my village but luckily an operator was providing
              night pack which means enough data for me to browse whole night at
              low cost which I couldn't let go.
              <br />
              <br />I started reading on google and and watching videos on
              Youtube later discovered some amazing programming channel which
              motivated me to learn to code and inspired to me to choose
              Computer Science and since then I never got bored learning to code
              and now I have enough understanding of programming that I can even
              teach programming to others.
            </p>

          </div>
          <div className="lmi-left my-auto  ">
            <img
              src={AboutImg}
              alt=""
              className="right   w-[600px] float-right "
            />
          </div>
        </section>
      
    </Container>
  );
}

export default About;

import React, { useState} from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { star, starStatic, twinkle, wrap } from "./ParticlesConfig";

function Particle() {
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };


 

  // useEffect(() => {
  //   setInterval (function () {
  //     const randomNum = Math.floor(Math.random()*colors.length);
  //     console.log(randomNum)
  //     setLineColor(colors[randomNum])
  
  //   }, 10000);
  // }, [])

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      // url={"https://raw.githubusercontent.com/matteobruni/tsparticles/main/website/presets/confetti.json"}
    

      options={starStatic}
     
    />
  );
}

export default Particle;

import React from "react";
import "./Home.css";
import TypeWriterEffect from "react-typewriter-effect";
import Container from "../Layout/Container";
import { motion } from "framer-motion";


import MyPic from "./../../assets/images/my-pic.png";

import HeroImg from "../../assets/images/code-typing-hero-img.svg";

function Home() {
  return (
    <>
     
   

      <Container>
        <section
          id="hero"
          className="hero mt-30 flex flex-col md:flex-row justify-between md:gap-40  text-xl md:text-4xl"
        >
          <div className="left my-16 w-auto flex justify-center flex-col ">
            <h1 className=" gap-4 leading-10 hover:leadin-8 font-Poppins ">
              Hye<span className="text-red-400">,</span>
              <br />
              <span className="md:ml-10 leading-10 tracking-widest ">
                I am <span className="text-primary font-Poppins font-bold"> Tauleshwar Thakur</span>
              </span>
              <br />
            </h1>
            <h1 className="type-writter mt-6">
              <TypeWriterEffect
                textStyle={{
                  fontFamily: "Red Hat Display",
                  color: "#ffff",
                  fontWeight: 500,
                  // fontSize: '1.5em',
                }}
                startDelay={2000}
                cursorColor="#3F3D56"
                multiText={[
                  "Campus Mantri @ GeeksForGeeks",
                  "Fullstack Developer",
                  "Computer Science Student",
                  "Content Creator",
                ]}
                multiTextDelay={1000}
                typeSpeed={50}
                multiTextLoop
              />
            </h1>
          </div>

          <div className="right text-center mx-auto mb-20 ">
            <img
              src={MyPic}
              alt=""
              className=" h-[400px] rounded-full"
            />
          </div>
        </section>

        <section className="let-me-introduce flex flex-col md:flex-row gap-8 justify-between w-full text-center ">
          <div className="lmi-right  h-full w-full text-xl ">
            <h1 className=" text-5xl font-Raleway ">Let Me <span className="text-purple-500">INTRODUCE</span> Myself</h1>
            <br />
            <p className="leading-relaxed">
              I started to learn to code (of course with HTML 😊) in Grade 9. 
          
              <br />
            <span>I can write C/C++, Javscript, Python and speak English, Hindi and Nepali.</span>
            <br />
            <br />
            <span>I spend most of my time Practicing DSA and Web Development.</span>
            <br />

            <span>Looking for an opportunity to put my skills of Javascript, Node.js, React.js, Next.js etc on work </span>
            <br />
            <span> and I am always ready to learn new things if it is required to complete the task of building something great.</span>
            </p>

          </div>
          <div className="lmi-left  md:w-6/12 ">
            <img
              src={HeroImg}
              alt=""
              className="right  md:h-5/6 float-right "
            />
          </div>
        </section>
        
      </Container>
    </>
  );
}

export default Home;

import React from "react";
import Container from "../Layout/Container";
import { useState } from "react";
import ProjectCard from "./ProjectCard";
import AngryDinaImg from "./../../assets/images/angrydina.gif";
import ByajImg from "./../../assets/images/byaj.png";

function Projects() {
  const [ProjectsList, setProjectsList] = useState([
    {
      name: "Angry Dina",
      description:
        "Angry dina is a game like Chrome's terex dinasour game which I made using Python",
      link: "https://github.com/tauleshwar/angry-dina",
      preview: { AngryDinaImg },
    },
    {
      name: "Byaj",
      description:
        "A simple android application which Calculates Compound Interest",
      link: "https://play.google.com/store/apps/details?id=com.tauleshwarthakur.byaj",
      preview: { ByajImg },
    },

    {
      name: "Tutorend",
      description: "Built a blog site with WP Rest API and Next Js",
      link: "https://tutorend.com",
      preview: { ByajImg },
    },
  ]);
  const Project0 = ProjectsList[0];
  const Project1 = ProjectsList[1];
  const Project2 = ProjectsList[2];
  // const Project3 = ProjectsList[3]

  return (
    <Container>
      <h1 className="font-Raleway text-3xl md:text-5xl text-center">
        My Recent Works{" "}
      </h1>
      <p className="text-center text-lg">
        Here are some of the projects on which I have recently worked on.
      </p>

      <section className="react-projects mt-10">
        <div class="grid-section md:mx-10 lg:mx-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  text-center  ">
          {/* {ProjectsList.map(project=><ProjectCard name={project.name} description={project.description} link={project.link} imgPath={project.preview} />)} */}

          <ProjectCard
            name={Project2.name}
            description={Project2.description}
            link={Project2.link}
            imgPath={
              "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAFwAXAMBEQACEQEDEQH/xAAbAAEAAwEBAQEAAAAAAAAAAAAAAwQFAQYCB//EADIQAAICAgADAwsDBQAAAAAAAAABAgMEEQUSMSFBgRM0QlFSYXGRobHRRHKSBhQiIzL/xAAaAQEAAwEBAQAAAAAAAAAAAAAAAgMEBQEG/8QAJxEBAAIBAgUEAgMAAAAAAAAAAAECAwQREiExMlETFDNBIlIFcZH/2gAMAwEAAhEDEQA/APyM8SAAAAAAAAAAAAAAAAACysLIlDmVfhvtIepVTOoxRO26v0en1JrnAAAAAAAAAACbESlk1p9OYjbtlXmnbHOzdiZXIY3Eklm2a79P6GnH2uppZ3xQqk14AAAAAAAAAnw/Oqv3Eb9sqs/x2bqMrksXifnk/gvsacfa6el+GFUm0AAAAAAAAHquD8Fx58PquurhbO2KnuST0n0SMmTLbimIczUai/qTWJ2iFp8Ixq5KUMalNdGoIh6lp+1M5rzG02l8WYyj6C+Q3RUb8fHcnKyiuT9bgmexa0dJexfJWNq2lA6sVfpqf4I947+Xk5c37T/qtmYcJrnxoqMl1guxP4FmPLMcrNOn1Vq/jknePLNa09M0Ok4AAAAAGrw7j2XgY/kIRrtrT/xVie4+5a7iq+Gtp3Z8ulpktxdJXaf6myrroQlRQlJ67E/yQtgrEb7qMmkpSk2iVuziNsusK/k/yVcMMWzJzOKWxulBV1tL3MtpiiY3bMOlrekWmVOWfZL0IL4bJ+jC32VPMuf3tnsxHpQezp5RXWu2XNKMVLva7yda8PJdixenG0TyREloAAAAAE2H51V+4jftlVn+OzYbM0uSyM17yZeH2NGPtdTS/FCAmvALFGFkZEeaqvcfW2lshbJWs7TKjJqcWKdrShnGUJOE04yT00+4nExMbwuraLRvHR8h6AAAACXFesmvftEb9sq83xy1ZyMzkszMTVzfc+hfjnerpaWYnHt4QFjQ6k5NKKbk+xJd4/smYiN5epwqfJ011+zFJnNyW4pmXzGfJx3tbzLG49yriU1HW1GPN8dfjRs0/wAbs/x2/t438yzi5uAAAAB1PTTXVAmN+TSjarYKS8V6jLaNp2cjJjnHbaXeWM1qS2jyJmOiEXtSd4lyODTJ9JLxPfWst97lhexMWql7rgub2n2sqvktbqyZtRkycrS0HOvHpldbLlhFbbKtptO0MdaWy3ilOsvJ5N0sjIsumtOct69R0q14axWH1OLHGKkUj6REkwAAAAAPuuyVb3Hx955asWjmhkx1vG0rdWXD004v5lE4p+mG+jvHbzWq8rGXa7V8mVzjv4Z7aXN+qSXFcepf61Kx+5aX1EYLz15PK/x+W3dyZmbm3Zkl5R6hH/mC6I0Y8dadHS0+mx4I/Hr5VixoAAAAAAAAAAPQPAAAAAAAAAAAAAAAAAAAf//Z"
            }
          />

          <ProjectCard
            name={Project0.name}
            description={Project0.description}
            link={Project0.link}
            imgPath={AngryDinaImg}
          />
          <ProjectCard
            name={Project1.name}
            description={Project1.description}
            link={Project1.link}
            imgPath={ByajImg}
          />
         
        </div>
      </section>
    </Container>
  );
}

export default Projects;
